import React from 'react'
import SvgIcon from '~/components/SvgIcon'
import { cn } from '~/utils/cn'

const size = 24
const radius = size * 0.4
const viewBox = `${size / 2} ${size / 2} ${size} ${size}`

export type SpinnerProps = PropsWithClassName

const Spinner = ({ className }: SpinnerProps) => {
  return (
    <div
      style={{ height: size, width: size }}
      className={cn(className, 'flex')}
      role="progressbar"
    >
      <SvgIcon
        viewBox={viewBox}
        className="h-full w-full origin-center animate-spin motion-reduce:animate-none"
      >
        <circle
          cx={size}
          cy={size}
          r={radius}
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          className="animate-growStrokeSpinner motion-reduce:animate-none"
          style={{
            strokeDasharray: '1,200',
            strokeDashoffset: '0',
            strokeLinecap: 'round'
          }}
        />
      </SvgIcon>
    </div>
  )
}

export default Spinner
